.TopSellerStoresPage {
    margin: 80px auto;
    padding: 24px;
    max-width: 1100px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 2px 2px 1px #ccc;
}

.TopReceiptsSendersPage h4 {
    margin-top: 2rem;
    text-transform: uppercase;
}

.TopReceiptsSendersPage h5 {
    margin-top: 2rem;
    text-transform: uppercase;
}

.TopReceiptsSendersPage h5 span {
    color: #00ee00;
}

.message {
    margin-top: 60px;
    font-size: 20px;
    color: #969595;
}

.TopSellerStoresPage .button-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
}

.btn-export-pdf {
    padding: 10px;
    margin: 2px 0px;
    border: none;
    background-color: #00ee00;
    border-radius: 8px;
    font-weight: 700;
    color: #fff;
}

.btn-export-pdf.btn-outline {
    background-color: transparent;
    border: 1px solid #00ee00;
    color: #00ee00;
}

.btn-filter {
    background-color: #2980b9;
}

.btn-export-pdf svg {
    font-size: 1.5rem;
    margin-right: 5px;

    stroke: #fff;
}

.filter-date-wrapper {
    display: flex;
    flex: 1;
    gap: 10px;
}

.filter-date-wrapper input[type="date"] {
    max-width: 400px;
}
