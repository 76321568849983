.MuiPaper-root {

    .MuiDialogTitle-root {

        h2 {
            font-size: 2em;
            margin: 0;
            font-weight: 700;
            text-align: center;
            font-family: Sora !important;
        }
    }

    .MuiDialogContent-root {
        p {
            margin: 0;
            text-align: justify;
            font-family: Sora !important;
            font-size: 1.4em;
            font-weight: 700;
        }
    }

    label {
        font-family: Sora;
        font-weight: 700;
    }

    button {
        padding: 6px 15px !important;
        border: none;
        width: auto;
        font-size: 1.2em;
        font-weight: 700;
        border-radius: 8px;
        color: #FFF;
        transition: .5s all;
    }

    .cancel {
        background-color: red;
        border: 5px solid red;

        &:hover {
            background-color: transparent;
            color: red;
        }

    }

    .confirm {
        background-color: #00EE00;
        border: 5px solid #00EE00;

        &:hover {
            background-color: transparent;
            color: #00EE00;
        }
    }
}

@media (max-width: 970px) {
    .MuiDialog-paperFullScreen {
        min-height: 400px !important;
        max-height: 400px !important;
        width: 80% !important;
    }
}

@media (max-width: 600px) {
    .MuiDialog-paperFullScreen {
        min-height: 100vh !important;
        max-height: 100vh !important;
        width: 100% !important;
    }
}