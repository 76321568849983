
#principal{
    #conteudo{
        justify-content: center;
        #dados{
            padding-top: 30px;

        }
        #endereco{
            padding-top: 30px;
 
        }
        #vendas{
            padding-top: 30px;
            display: block;
        }
    }
}
