.titleNumber {
    font-weight: 700;
    font-size: 1.7em;
}

.container {
    overflow: auto;

    .table {
        width: 100%;
        background-color: #FFF;
        border-radius: 15px;
        padding: 20px;

        .thead-dark {
            text-align: center;

            .TableRowHead {

                .CellHead {
                    border: none;
                }
            }
        }

        th {
            button {
                border: 5px solid #00EE00;
                border-radius: 10px;
                background-color: #00EE00;
                color: #FFF;
                width: 140px;
                font-size: 1em;
                font-weight: 700;
                transition: .4s all;

                &:hover {
                    background-color: #FFF;
                    color: #00EE00;
                    transform: scale(1.04);
                }
            }

        }


        .thead-darkSecond {
            text-align: center;
        }
    }
}

.table>:not(caption)>*>* {
    border-bottom-width: 0px;
}