@import url("https://fonts.googleapis.com/css?family=Muli");
$Gradiente_Fundo_body: linear-gradient(90deg, #e0dcdc 0%, #e0dcdc 100%);
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

* {
    margin: 0;
    padding: 0;
    font-family: Sora, "Roboto";
    font-style: normal;
}

html {
    height: 100vh;
}

body {
    background: $Gradiente_Fundo_body;
    // min-height: 100%;
    overflow: auto;
}

img {
    display: block;
}

@media print {
    .default-page,
    .container,
    .default-print-layout {
        width: 100% !important;
        max-width: 100vh !important;
        margin: 0 auto !important;
        height: 100vh !important;
        padding: 40px 30px !important;

        .card {
            .title-wrapper {
                span {
                    font-size: 10px !important;
                }
            }

            .icon-wrapper {
                width: 36px !important;
                height: 36px !important;

                svg {
                    font-size: 16px !important;
                }
            }
        }

        .payment-card,
        .category {
            span {
                &.main-text {
                    font-size: 18px !important;
                }
            }

            .secondary-text {
                font-size: 12px !important;
            }
        }

        .message-payment {
            font-size: 12px !important;
        }
    }

    body,
    html {
        background-color: #fff !important;
    }
}
