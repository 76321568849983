#content {
  width: 100%;
  height: 100vh;
  background-color: var(--gray1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  scrollbar-face-color: #520a0a;
  overflow-y: auto;
  min-width: 320px;
}

#content .box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

#content .box .graph {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  background-color: #FFF;
  width: 320px;
  height: auto;
  border-radius: 12px;
  border: 1px solid var(--gray1);
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
    0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 10px;
  transition: .6s all;

  &:hover {
    transform: scale(1.04);
  }


  .buttonGraph {
    text-decoration: none;
    border-radius: none;
    border: none;
    background-color: #FFF;
    color: #000;
    font-weight: 700;


    &:hover {
      transition: 5s;
    }
  }

}

#content .box .graph div:nth-child(1) {
  text-align: center;
  margin-left: 25px;

}

#content .box .graph div:nth-child(1) span {
  font-size: 1.5rem;
  color: var(--gray2);
}

#content .box .graph div:nth-child(1) h5 {
  color: var(--blue2);

}

#content .box .graph div:nth-child(2) {
  padding-top: 5px;
  padding-left: 15px;
  border-top: 1px solid var(--gray1);
  font-size: 40px;
  color: var(--blue3);
  cursor: pointer;

  a {
    margin-left: 60px;
  }

}

#content .box .graph div:nth-child(2) a {
  color: var(--blue3);
  text-decoration: none;
}

#content .box .graph div:nth-child(3) {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  top: -15px;
  left: 5px;

  color: var(--gray1);
  border-radius: 3px;
  box-shadow: 1px 1px 3px var(--gray2);
  font-size: 35px;
}