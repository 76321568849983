.TopSellerStoresPage {
    margin: 80px auto;
    padding: 24px;
    max-width: 1100px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 2px 2px 1px #ccc;
}

.TopSellerStoresPage .latest-sales-table {
    margin-top: 2rem;
}

.message {
    margin-top: 60px;
    font-size: 20px;
    color: #969595;
}

.CustomersStoreReportsPage table.listPromoCodes {
    /* border: 3px solid #000; */
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.CustomersStoreReportsPage table.monthlyCustomers thead {
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
    font-size: 1rem;
    color: #fff;
    text-transform: uppercase;
}
