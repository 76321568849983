.default-print-layout {
    width: 100%;
    background-color: white;
    padding: 40px 60px;

    .tabela {
        position: relative;
    }

    .logo {
        filter: opacity(0.8);
        width: 100px;
        position: absolute;
        bottom: -80px;
        right: 0;
    }

    .report-heading {
        margin-bottom: 80px;

        h1 {
            color: #646464;
            font-weight: 500;
            font-size: 26px;
        }

        p {
            color: #01003f;
            font-weight: bold;
            font-size: 20px;
            text-transform: uppercase;
        }
    }

    .section-title {
        h6 {
            color: #01003f;
            font-size: 18px;
            font-weight: 800;
        }
    }

    .card {
        padding: 16px;
        border-radius: 5px;
        box-shadow: 0 1px 1px 0 rgba($color: #000000, $alpha: 0.1);

        h6 {
            color: #646464 !important;

            font-size: 14px;
        }

        p {
            font-size: 20px;
            font-weight: bold;
            color: #353535;
        }

        .main-text {
            font-size: 24px;
            font-weight: bold;
            color: #01003f;
        }

        .secondary-text {
            font-size: 14px;
            color: #646464;
            font-weight: 600 !important;
        }

        .title-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            span {
                font-size: 16px;
                color: #646464;
                color: #42415a !important;
            }
        }

        .icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #01003f, $alpha: 0.1);

            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-bottom: 32px;

            svg {
                font-size: 24px;
                color: #01003f;
            }
        }

        .gold {
            background-color: rgba($color: #d1ce00, $alpha: 0.3);
            svg {
                color: #d1ce00;
            }
        }

        .silver {
            background-color: rgba($color: #d6d6d6, $alpha: 0.3);
            svg {
                color: #b4b4b4;
            }
        }

        .bronze {
            background-color: rgba($color: #c26e00, $alpha: 0.3);
            svg {
                color: #c26e00;
            }
        }

        .sales-icon {
            background-color: rgba($color: #006d05, $alpha: 0.3);
            svg {
                color: #008f07;
            }
        }
    }

    .cards-row,
    .message-payment {
        margin-bottom: 60px;
    }

    .table {
        max-width: calc(100vw - 60px);
    }

    // .default-page {
    //     margin: 80px auto;
    //     padding: 24px;
    //     width: 80%;
    //     border-radius: 8px;
    //     background-color: white;
    // }
}

.quick-access-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        border-bottom: none;
    }
}

.tabela {
    thead {
        font-weight: 400;
        font-size: 14px;
    }

    tbody {
        vertical-align: middle;
    }

    td {
        font-weight: 400;
        font-size: 14px;
    }

    .btn {
        font-size: 12px;
    }

    .btn-lucrei-alt {
        // purple lucrei
        background-color: #56308d;
        color: #fff;
        border: none;

        &:hover {
            background-color: #462574;
        }
    }

    .filter-wrapper {
        display: flex;
        // nao sabia, fazer com o que o flex-items ocupem toda a altura do flex-container
        align-items: stretch;
        justify-content: end;
        gap: 5px;
    }

    .category-name {
        display: block;
        font-weight: 400 !important;
    }
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    p {
        margin: 0;
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .NewCouponWrapper {
        padding: 12px;
    }

    .promoCodeFormRow {
        flex-direction: column;
    }

    .promoCodeFormComponent {
        width: 100%;
    }

    .promoCodeFormSubmit {
        width: 100%;
    }
}
