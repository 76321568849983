.NewCouponWrapper {
    margin: 80px auto;
    padding: 24px;
    width: 80%;
    border-radius: 8px;
    background-color: white;
}

.promoCodeForm {
    display: flex;
    flex-direction: column;
}

.promoCodeFormComponent {
    width: 350px;
    margin: 5px 0px;
}

.promoCodeFormComponent label {
    font-size: 1rem;
    font-weight: 700;
}

.promoCodeFormRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.promoHeader {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.promoSubHeader {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
}

.promoCodeFormSubmit {
    font-size: 1rem;
    margin: 10px 0;
    background: #009c60;
    border: none;
    border-radius: 7px;
    width: 300px !important;
    height: 50px;
    color: #fff;
    cursor: pointer;
    transition: ease-in-out 200ms;
}

.promoCodeFormSubmit:hover {
    transition: ease-in-out 200ms;
    box-shadow: 2px 5px 16px 0px #0b325e, 5px 5px 15px 5px rgba(0, 156, 96, 0);
}

.promoCodeFormAlter {
    font-size: 10px;
    margin: 10px 0px;
    background: #56308d;
    border: none;
    border-radius: 7px;
    width: 300px;
    height: 40px;
    color: #fff;
    cursor: pointer;
    transition: ease-in-out 200ms;
}

.promoCodeFormAlter:hover {
    transition: ease-in-out 200ms;
    box-shadow: 2px 5px 16px 0px #0b325e, 5px 5px 15px 5px rgba(0, 156, 96, 0);
}

@media screen and (max-width: 768px) {
    .NewCouponWrapper {
        padding: 12px;
    }

    .promoCodeFormRow {
        flex-direction: column;
    }

    .promoCodeFormComponent {
        width: 100%;
    }

    .promoCodeFormSubmit {
        width: 100%;
    }
}
