.ModalDeposito {
  position: fixed;
  z-index: 200;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(#000, 0.6);

  .ModalWrapper {
    position: absolute;
    background-color: #fff;
    max-width: 90%;
    text-align: center;
    padding: 18px 0;

    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
      0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .DepositForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 16px;
    //botao fechar x
    .closeButton {
      position: relative;
      color: #000;
      left: 10.5rem;
      bottom: 1.5rem;
      background-color: white;
      opacity: 1;
      border: 2px solid #222;
      outline: none;
      border-radius: 70%;
      padding: 10px;
      width: 10px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
      }
    }

    label {
      font-weight: bold;
    }
  }
  .valueInfo {
    width: 100%;
    padding: 16px;
    margin-bottom: 32px;
    border-radius: 5px;
    background-color: #009c60;
    color: #fff;
    text-align: left;

    label {
      font-size: 14px;
      font-weight: 400;
      margin: initial;
    }
    h2 {
      margin-top: 4px;
      font-weight: 800;
    }
  }
  .button {
    width: 100%;
    border-radius: 5px;
    border: none;
    user-select: none;
    text-transform: uppercase;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.75;
      cursor: wait;
    }

    .progress {
      background: none;
      margin-left: 8px;
    }
  }

  .buttonGroup {
    width: 100%;
    margin-top: 32px;

    .buttonConfirm {
      width: 100%;
      height: 30px;
      background: #09865f;

      box-shadow: 0px 13.4869px 20.2304px rgba(0, 0, 0, 0.14),
        0px 5.05759px 25.288px rgba(0, 0, 0, 0.12),
        0px 6.74346px 8.42932px rgba(0, 0, 0, 0.2);
      color: #fff;
      border: none;
      border-radius: 10px;
      margin-top: 8px;

      &:hover {
        background: #05fe9f;
        transform: scale(1.01);
      }

      @media screen and (max-width: 768px) {
        width: 70%;
      }
    }

    .buttonCancel {
      width: 100%;
      background: #9c0000;
      box-shadow: 0px 10.7429px 16.1143px rgba(0, 0, 0, 0.14),
        0px 4.02857px 20.1429px rgba(0, 0, 0, 0.12),
        0px 5.37143px 6.71429px rgba(0, 0, 0, 0.2);
      color: #fff;
      border: none;
      margin-top: 8px;
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: initial;
  }

  h1 {
    margin-top: 4px;
    font-weight: 800;
    font-size: 30px;
  }

  .progress {
    width: 36px;
    height: 36px;
  }

  .group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;

    width: 100%;

    label {
      font-size: 0.75rem;
      width: 100%;
    }
    span {
      font-size: 0.75rem;
    }

    .formInput {
      width: 100%;
      height: 41px;
      border-radius: 7.54px;
      border: 1px solid #c6c8c9;
      padding: 8px;
      font-size: 16px;

      outline: none;

      &:focus {
        background: #fff;
        box-shadow: 0 0 0 3px #05fe9f;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .buttonGroup {
    display: flex;

    .buttonConfirm {
      order: 1;
      margin-left: 6px;
    }

    .buttonCancel {
      margin-top: initial;
    }
  }
}

@media screen and (min-width: 1024px) {
  .ModalWrapper {
    padding: 12px 0;
  }

  .DepositForm {
    padding: 0 12px;
  }

  .group {
    label {
      font-size: 0.875rem;
    }

    .formInput {
      font-size: 0.875rem;
      text-align: center;
    }
  }
}
.cupomImg {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    font-size: 0.625rem;
    color: #979797;
  }
}
