.default-page {
    margin: 80px auto;
    padding: 24px;
    width: 80%;
    border-radius: 8px;
    background-color: white;

    .card-body {
        border: initial;
        border-radius: initial;
        padding: 0;
        margin-top: 1rem;

        h5 {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }

    .card-text {
        margin-bottom: 0;
    }
}

.quick-access-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        border-bottom: none;
    }
}

.tabela {
    thead {
        font-weight: 400;
        font-size: 14px;
    }

    tbody {
        vertical-align: middle;
    }

    td {
        font-weight: 400;
        font-size: 14px;
    }

    .btn {
        font-size: 12px;
    }

    .btn-lucrei-alt {
        // purple lucrei
        background-color: #56308d;
        color: #fff;
        border: none;

        &:hover {
            background-color: #462574;
        }
    }
}

@media screen and (max-width: 768px) {
    .NewCouponWrapper {
        padding: 12px;
    }

    .promoCodeFormRow {
        flex-direction: column;
    }

    .promoCodeFormComponent {
        width: 100%;
    }

    .promoCodeFormSubmit {
        width: 100%;
    }
}
