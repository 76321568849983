.CustomerSalesDetailsPage {
    margin: 80px 0 10px 100px;
    padding: 16px;
    max-width: 1200px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 2px 2px 1px #ccc;
    min-height: calc(100vh - 20px);

    .purchases-table-wrapper {
        padding: 1rem;
    }

    .user-info-wrapper {
        margin: 2rem 0 4rem 0;
    }

    h1 {
        color: #333;
    }

    .btn-links-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .btn-link-purchases {
        padding: 10px;
        margin: 2px 0px;
        border: none;
        background-color: #00ee00;
        border-radius: 8px;
        color: #fff;
        font-weight: 700;
    }

    .user-info {
        padding: 1rem;
        border: 1px solid rgba($color: #ddd, $alpha: 0.7);
        border-radius: 2px;
        display: flex;
        gap: 2rem;
        box-shadow: 2px 2px 1px #ccc;
        svg {
            margin-right: 4px;
            color: #6e768e;
        }
    }

    .col-receipt-code {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .col-receipt-code span {
        cursor: pointer;
        text-decoration: underline;
        color: #3498db;
    }

    .primary-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .contact-info {
        padding: 1rem;
    }

    .user-avatar {
        text-align: center;
    }

    .secondary-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
    }
}
