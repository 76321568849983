.tableDeposit {
    padding-left: 0 !important;
}

.TableWrapper {
    padding-top: 20px;

    h5 {
        font-weight: 700;
    }

    .container {
        flex-direction: column;

        .table {
            .TableRowHead {
                .CellHead {
                    text-align: center;
                }
            }

            th {
                text-align: center;
            }
        }
    }
}