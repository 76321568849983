.AlingTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TopSellerStoresPage {
    margin: 80px auto;
    max-width: 1100px;
    border-radius: 4px;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
}


.TopSellerStoresPageAlter {
    width: 90%;
    margin: 80px auto;
}

.message {
    margin-top: 60px;
    font-size: 20px;
    color: #969595;
}

.CustomersStoreReportsPage {
    max-width: 1100px;
    width: 90%;
    margin: 0 auto;
    overflow: auto;
}

.CustomersStoreReportsPage table.listPromoCodes {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.CustomersStoreReportsPage table.monthlyCustomers thead {
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
    font-size: 1rem;
    color: #fff;
    text-transform: uppercase;
}

.table {
    background-color: #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.table>thead {
    text-align: center;
    font-size: 1.5em;
    font-weight: 700;
}

.table>tbody {
    text-align: center;
}

tr,
td {
    border: none !important;
    font-weight: 600;
}

a {
    color: #000;
    text-decoration: none;
    transition: .5s all;
    border-bottom: 2px solid #00EE00;
}

a:hover {
    color: #00EE00;
}

@media (max-width: 1200px) {
    .AlingTable {
        overflow: auto;
        width: 800px;
        margin: 0 auto;
    }

    /* .TopSellerStoresPage {
        width: 100%;
        overflow-x: auto;
    } */

    .table {
        width: 800px;
    }

}