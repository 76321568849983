.ConfirmationReceiptAcceptModal {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ConfirmationReceiptAcceptModal .bonus-form {
    background-color: white;
    width: 90%;
    max-width: 500px;
    padding: 18px 16px;
    border-radius: 4px;
    position: relative;
}

.bonus-form input,
label {
    display: block;
}

.bonus-form button {
    width: 100%;
    padding: 8px 12px;
    color: #fff;
    border: 1px solid #eee;
    border-radius: 7px;
    cursor: pointer;
    font-size: 0.875rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}
