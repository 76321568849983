.dadosEmpresa {
  height: auto;
  margin-top: 30px;
  display: flex;
  width: 100%;

  .container {
    height: auto;
    margin-top: 30px;
    display: flex;
    color: #000;
    background-color: #fff;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
      0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;




    .containerDados>form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .logo {
      margin-top: 10px;

      img {
        border-radius: 20px;
        width: 100%;
      }
    }

    .person {
      width: 100%;
      padding: 20px;

      .sectionButton {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        align-items: center;
      }

      label {
        margin-left: 8px !important;
        margin-top: 10px;
        font-size: 1.5em;
        font-family: Sora;
        color: #000;
        font-weight: 700;
      }

      .Alinhamento {
        width: 100%;

        input {
          font-size: 1.2em;
          font-family: Sora;
          font-weight: 700;
          color: #0000008A;
          padding: 1px 2px;
          border-bottom: 2px dashed #000;
        }
      }

      .btnComissao,
      .btnShop {
        margin-top: 20px;

        .comissao {
          width: 200px;
          min-height: 100px;
          font-size: 20px;
          background: #00EE00;
          color: #fff;
          border: none;
          padding: 8px;
          font-weight: 700;
          border-radius: 5px;

          p {
            margin: 0;
          }

          &:hover {
            opacity: 0.75;

            color: #fff;
          }
        }

        .shopLucrei {
          width: 200px;
          min-height: 100px;
          font-size: 20px;
          background: #00EE00;
          color: #fff;
          border: none;
          padding: 10px;
          font-weight: 700;
          border-radius: 8px;

          p {
            margin: 0;
          }

          &:hover {
            opacity: 0.75;

            color: #fff;
          }
        }
      }

    }

    .store {
      width: 100%;
      float: left;
      padding: 20px;

      label {
        margin-left: 8px !important;
        margin-top: 10px;
        font-size: 1.5em;
        font-family: Sora;
        color: #000;
        font-weight: 700;

      }

      .Alinhamento {
        width: 100%;

        input {
          font-size: 1.2em;
          font-family: Sora;
          font-weight: 700;
          color: #0000008A;
          padding: 1px 2px;
          border-bottom: 2px dashed #000;
        }
      }

      input {
        width: 100%;
      }
    }


  }




  @media (max-width: 600px) {
    .container {
      flex-wrap: wrap;
      width: 90vw;
      margin: auto;

      .logo {
        margin-top: 10px;
      }

      .dados2 {
        padding: 20px;
      }
    }
  }
}