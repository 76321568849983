.MainReportsPage {
    margin: 80px auto;
    padding: 24px;
    border-radius: 15px;

    max-width: 1100px;
    background-color: white;
    box-shadow: 2px 2px 1px #ccc;
}

.MainReportsPage>.row>h3 {
    font-weight: 700;
    font-size: 2.3em;
}

.card-body {
    border: 3px solid #000;
    border-radius: 15px;
}

.card-body>h5 {
    font-size: 2em;
    font-weight: 700;
}

.card-body>ul>li {
    font-size: 1.2em;
    font-weight: 600;
}

.message {
    margin-top: 60px;
    font-size: 20px;
    color: #969595;
}

.MainReportsPage .card-links {
    height: 100%;
}

@media (max-width: 1320px) {
    .MainReportsPage {
        width: 80%;
    }

    .groupItems {
        flex-direction: column;
        gap: 20px;
    }

    .groupItems>.col-6 {
        width: 60%;
        margin: 0 auto;
    }

}

@media (max-width: 1100px) {
    .groupItems>.col-6 {
        width: 70%;
    }
}

@media (max-width: 1100px) {
    .MainReportsPage {
        width: 100%;
        border-radius: 0;
    }

    .groupItems>.col-6 {
        width: 70%;
        margin: 0 auto;
    }
}