.Register {
  // background: linear-gradient(90deg, #009c60 0%, #009c60 100%);
  // margin-top: 100px;
  font-weight: 700 !important;
  padding: 100px 0px 30px 0px;
  // margin-bottom: 100px;
}

#logo {
  margin: 1% auto;
  display: flex;
}

#cadastro {
  padding: 25px;
  //height: 700px;
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
    0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  .infoEmpresa {
    border: 1px solid #cdc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px;
  }

  .infoResponsavel {
    border: 1px solid #cdc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px;
  }

  .infoLocalização {
    border: 1px solid #cdc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px;
  }

  .infoNegocios {
    border: 1px solid #cdc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px;
  }

  .infoSenhas {
    border: 1px solid #cdc;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px;
  }

  p {
    font-size: 12pt;
    text-decoration: underline;
  }

  h5 {
    text-align: center;
  }

  .formInput {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .inputControl {
      margin: 3px 3%;

      .campo {
        padding: 10px;
        width: 300px;
        height: 50px;
        border: 1px solid #cdc;
        border-radius: 4px;
      }

      .camposelect {
        padding: 0 10px;
        width: 325px;
        height: 50px;
        border: 1px solid #cdc;
        border-radius: 4px;
      }

      .Infomations {
        display: flex;

        .Error {
          color: red;
          flex: none;
          font-size: 12px;
        }
      }
    }
  }

  .termosInput {
    display: flex;
    margin: 2% auto;
    justify-content: center;

    .termos {
      margin: 0% 1%;
      height: 20px;
      width: 20px;
    }

    .aceito {
      font-size: 0.875rem;
    }
  }

  .submitInput {
    margin: 25px 20px 0 28px;
    display: flex;
    justify-content: center;

    .submit {
      background: #00EE00;
      border: 5px solid #00EE00;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14),
        0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      width: 322px;
      font-weight: 700;
      font-size: 1.5em;
      height: 50px;
      color: #fff;
      transition: .6s all;
      cursor: pointer;

      &#submit:hover {
        background-color: #fff;
        color: #00EE00;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

@media screen and (max-width: 777px) {
  #cadastro {
    //width: 600px;
    //height: 600px;
    margin: 0% auto;

    .formInput {
      .inputControl {
        .campo {
          padding: 0 10px;
          width: 250px;
          height: 50px;
        }

        .camposelect {
          width: 225px;
          height: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 627px) {
  #cadastro {
    //width: 500px;
    //height: 550px;
    margin: 0% auto;

    .formInput {
      .inputControl {
        .campo {
          width: 200px;
          height: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 510px) {
  #cadastro {
    //width: 450px;
    //height: 430px;
    margin: 0% auto;

    h5 {
      font-size: 1rem;
    }

    label {
      font-size: 0.675rem;
    }

    .formInput {
      .inputControl {
        .campo {
          width: 250px;
          height: 50px;
        }

        .camposelect {
          width: 175px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 473px) {
  #cadastro {
    //width: 450px;
    //height: 430px;

    h5 {
      font-size: 1rem;
    }

    label {
      font-size: 0.675rem;
    }

    .formInput {
      .inputControl {
        .campo {
          width: 250px;
          height: 50px;
        }

        .camposelect {
          width: 175px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 465px) {
  #cadastro {
    //width: 370px;
    //height: 740px;
    overflow-y: scroll !important;

    h5 {
      font-size: 1rem;
    }

    label {
      font-size: 0.675rem;
    }

    .formInput {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .inputControl {
        .campo {
          width: 250px;
          ;
          height: 50px;
        }

        .camposelect {
          width: 300px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 415px) {
  #cadastro {
    //width: 370px;
    //height: 720px;
    overflow-y: scroll !important;

    h5 {
      font-size: 0.785rem;
    }

    label {
      font-size: 0.675rem;
    }

    .formInput {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .inputControl {
        .campo {
          width: 250px;
          height: 50px;
        }

        .camposelect {
          width: 320px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 376px) {
  #cadastro {
    //width: 350px;
    //height: 750px;
    overflow-y: scroll !important;

    h5 {
      font-size: 0.785rem;
    }

    label {
      font-size: 0.675rem;
    }

    .formInput {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .inputControl {
        .campo {
          width: 250px;
          height: 50px;
        }

        .camposelect {
          width: 300px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 376px) and (max-height: 667px) {
  #cadastro {
    width: 350px;
    height: 640px;
    overflow-y: scroll !important;

    .formInput {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .inputControl {
        .campo {
          width: 250px;
          height: 50px;
        }

        .camposelect {
          width: 270px;
          height: 50px;
          ;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 360px) and (max-height: 640px) {
  #cadastro {
    width: 310px;
    height: 620px;

    .formInput {
      .inputControl {
        .campo {
          width: 250px;
          height: 50px;
        }

        .camposelect {
          width: 270px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 320px) and (max-height: 569px) {
  #cadastro {
    width: 290px;
    height: 568px;

    .formInput {
      .inputControl {
        .campo {
          width: 250px;
          height: 50px;
        }

        .camposelect {
          width: 250px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 303px) {
  #cadastro {
    width: 250px;
    height: 740px;

    .formInput {
      .inputControl {
        .campo {
          width: 190px;
          height: 50px;
        }

        .camposelect {
          width: 210px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 280px) {
  #cadastro {
    width: 250px;
    height: 640px;

    .formInput {
      .inputControl {
        .campo {
          width: 190px;
          height: 50px;
        }

        .camposelect {
          width: 210px;
          height: 50px;
        }
      }
    }

    .submitInput {
      margin-top: -1px;

      .submit {
        width: 230px;
      }
    }
  }
}