.CustomersSearchPage {
    margin: 80px auto;
    padding: 24px;
    max-width: 1100px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 2px 2px 1px #ccc;
}

.message {
    margin-top: 60px;
    font-size: 20px;
    color: #969595;
}
