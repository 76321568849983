.TopSellerStoresPage {
    margin: 80px auto;
    padding: 24px;
    max-width: 1100px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 2px 2px 1px #ccc;
}

.message {
    margin-top: 60px;
    font-size: 20px;
    color: #969595;
}

.CustomersStoreReportsPage table.listPromoCodes {
    /* border: 3px solid #000; */
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.CustomersStoreReportsPage table.monthlyCustomers thead {
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
    font-size: 1rem;
    color: #fff;
    text-transform: uppercase;
}

.CustomersStoreReportsPage table.monthlySales thead {
    background-color: #198754;
    border: 1px solid #198754;
    font-size: 1rem;
    color: #fff;
    text-transform: uppercase;
}

.CustomersStoreReportsPage table.listpromoCodes td,
th {
    /* border: 1px solid #CFCFCF; */
    padding: 10px;
}

.CustomersStoreReportsPage table.listpromoCodes th,
td {
    border: 1px solid rgb(192, 192, 192);
    padding: 10px;
}

.CustomersStoreReportsPage table.listpromoCodes td {
    font-size: 13px;
}

.table-monthly-customers {
    margin-top: 4rem;
}

.table-monthly-sales {
    margin-top: 4rem;
}
