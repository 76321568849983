.container{
    width: 100%;
    height: auto;
    display: flex;
    .tableMarket{
        justify-content: center;
        .contentTable{
            background-color: #fff;
            border: 2px solid #000;
            
            
            
            .labelTable{    
                border: 2px solid #000;
              
                #label{
                    padding: 10px;
                    font-size: 15px;
                }
            }
            .seguimentName{
                #name{
                    padding: 15px;
                    border: 2px solid #000;
                }
            }

        }
    }
}