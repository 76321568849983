.ListReceiptsWrapper {
    margin: 80px auto 80px auto;
    padding: 24px 12px;
    max-width: 1200px;
    width: 80%;
    box-shadow: 4px 3px 3px #ccc;
    border-radius: 8px;
    background-color: white;
    overflow-x: scroll;
}

.ListReceiptsWrapper .info-receipts {
    display: flex;
    align-items: stretch;
    gap: 20px;
}

.ListReceiptsWrapper .info-receipts-status {
    margin: 1rem 0 2rem 0;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.ListReceiptsWrapper .card {
    flex: 1;
    padding: 1rem;
}

.info-receipts .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: row;
}

.info-receipts-status .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: row;
}

.ListReceiptsWrapper .card-info {
    font-size: 2rem;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);
}

.ListReceiptsWrapper .icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ListReceiptsWrapper .info-receipts .icon-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ListReceiptsWrapper .info-receipts .card svg {
    font-size: 2.2rem;
    color: #fff;
}

.card-info .green {
    color: #009c60;
}

.card-info .primary-blue {
    color: cornflowerblue;
}

.card-info .red {
    color: lightcoral;
}

.card-info .light-green {
    color: lightgreen;
}

.card-info .yellow {
    color: #fed330;
}

.icon-wrapper.green {
    background-color: #009c60;
}

.icon-wrapper.primary-blue {
    background-color: cornflowerblue;
}

.icon-wrapper.red {
    background-color: lightcoral;
}

.icon-wrapper.light-green {
    background-color: lightgreen;
}

.icon-wrapper.yellow {
    background-color: #fed330;
}

.ListReceiptsWrapper .card svg {
    font-size: 1.8rem;
    color: #fff;
}

.ListReceiptsWrapper .filter-wrapper {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    gap: 1.5rem;
}

.ListReceiptsWrapper .filter-wrapper .filter {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.ListReceiptsWrapper .buttonAlter {
    background-color: #34ca34;
    border: none;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1.2em;
    color: #fff;
    border: 3px solid #34ca34;
    padding: 5px 10px;
    transition: 0.6s all;
}

.buttonAlter:hover {
    background-color: #fff;
    color: #34ca34;
}

.ListReceiptsWrapper .filter-wrapper button {
    padding: 10px;
    margin: 2px 0px;
    border: none;
    background-color: #00ee00;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
}

.ListReceiptsWrapper .link-report-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.ListReceiptsWrapper .link-report-wrapper a {
    padding: 10px;
    margin: 2px 0px;
    border: none;
    background-color: #00ee00;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
}

.listPromoCodesMobileWrapper {
    overflow: auto;
    border-radius: 15px !important;
}

.ListReceiptsWrapper table .listPromoCodes {
    /* border: 3px solid #000; */
    width: 1200px;
    overflow: auto;
    /* text-align: center; */
    /* border-collapse: collapse; */
}

.ListReceiptsWrapper table.listPromoCodes thead {
    background-color: #00ee00;
    border-bottom: none;
    font-size: 1rem;
    color: #fff;
}

.ListReceiptsWrapper table.listpromoCodes td,
th {
    /* border: 1px solid #CFCFCF; */
    /* padding: 10px; */
}

.ListReceiptsWrapper table.listpromoCodes th,
td {
    /* border: 1px solid #000; */
    /* padding: 10px; */
}

.ListReceiptsWrapper table.listpromoCodes td {
    font-size: 12px;
}

button.actionToggleBTN-Ativar {
    border: none;
    background-color: #009c60;
    padding: 5px;
    width: 100px;
    border-radius: 10px;
    color: #cfcfcf;
    transition: ease-in 200ms;
}

button.actionToggleBTN-Ativar:hover {
    transition: ease-out 200ms;
    box-shadow: 2px 5px 16px 0px #0b325e, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

button.actionToggleBTN-Desativar {
    border: none;
    background-color: red;
    padding: 5px;
    width: 100px;
    border-radius: 10px;
    color: #cfcfcf;
    transition: ease-in 200ms;
}

button.actionToggleBTN-Desativar:hover {
    transition: ease-out 200ms;
    box-shadow: 2px 5px 16px 0px #0b325e, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

button.seeMoreBTN {
    border: none;
    background-color: #56308d;
    padding: 5px;
    width: 100px;
    border-radius: 10px;
    color: #cfcfcf;
    transition: ease-in 200ms;
}

button.seeMoreBTN:hover {
    transition: ease-out 200ms;
    box-shadow: 2px 5px 16px 0px #0b325e, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.col-receipt-code {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col-receipt-code span {
    cursor: pointer;
    text-decoration: underline;
    color: #3498db;
}

.ListReceiptsWrapper .search-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5rem;
}

.ListReceiptsWrapper .search-wrapper input[type="search"] {
    padding: 10px;
    margin: 2px 0px;
    border: 2px solid #00ee00;
    outline: none;
    border-radius: 8px;
    color: #222;
    font-weight: 700;
    margin-right: 6px;
    max-width: 800px;
    flex: 1;
}

.ListReceiptsWrapper .search-wrapper .btn-search {
    padding: 12px;
    margin: 2px 0px;
    border: none;
    outline-color: #00ee00;
    background-color: #00ee00;
    outline-width: 2px;
    border-radius: 8px;
    color: #fff;
    font-weight: 700;
    margin-right: 6px;
}

.ListReceiptsWrapper .search-wrapper .btn-list-all {
    padding: 11px;
    margin: 2px 0px;
    border: none;
    background-color: transparent;
    border: 1px solid #00ee00;
    border-radius: 8px;
    color: #00ee00;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    div.listPromoCodesMobileWrapper {
        overflow: scroll;
    }
}

@media screen and (max-width: 954px) {
    .ListReceiptsWrapper {
        width: 90%;
        margin: 70px auto;
    }
}

@media screen and (max-width: 500px) {
    .ListReceiptsWrapper {
        width: 100%;
    }
}
