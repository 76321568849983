.default-page {
    margin: 80px auto;
    padding: 24px;
    width: 80%;
    border-radius: 8px;
    background-color: white;
    height: initial;
}

.quick-access-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        border-bottom: none;
    }
}

.tabela {
    thead {
        font-weight: 400;
        font-size: 14px;
    }

    tbody {
        vertical-align: middle;
    }

    td {
        font-weight: 400;
        font-size: 14px;
    }

    .btn {
        font-size: 12px;
    }

    .btn-lucrei-alt {
        // purple lucrei
        background-color: #56308d;
        color: #fff;
        border: none;

        &:hover {
            background-color: #462574;
        }
    }

    .filter-wrapper {
        display: flex;
        // nao sabia, fazer com o que o flex-items ocupem toda a altura do flex-container
        align-items: stretch;
        justify-content: end;
        gap: 5px;
    }

    &.tabela-coupons {
        margin-bottom: 60px;
    }
}

.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    p {
        margin: 0;
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .NewCouponWrapper {
        padding: 12px;
    }

    .promoCodeFormRow {
        flex-direction: column;
    }

    .promoCodeFormComponent {
        width: 100%;
    }

    .promoCodeFormSubmit {
        width: 100%;
    }
}
