.containerAddress {
  height: auto;
  margin-top: 30px;
  display: flex;
  color: #000;
  background-color: #fff;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
    0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 100%;

  .labelCard {
    padding: 20px;
  }

  .address1 {
    width: 100%;
    padding: 20px;

    p {
      margin: 6px 0;
      font-weight: 700;
      font-size: 1.3em;
    }


    input {
      width: 100%;
      font-weight: 700;
      border: 3px solid #000;
      padding: 5px 10px;
      background-color: #fff;
      height: 30px;
      border-radius: 5px;
    }


  }

  .address2 {
    width: 100%;
    float: left;
    padding: 20px;


    p {
      margin: 6px 0;
      font-weight: 700;
      font-size: 1.3em;
    }

    input {
      width: 100%;
      font-weight: 700;
      border: 3px solid #000;
      padding: 5px 10px;
      background-color: #fff;
      height: 30px;
      border-radius: 5px;
    }
  }


}



@media (max-width: 600px) {
  .containerAddress {
    flex-wrap: wrap;
    margin: auto;
    width: 90vw;

    .address2 {
      padding: 20px;
    }
  }
}