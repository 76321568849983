.containerAdmin {
    height: 100vh;
    background-color: rgb(224, 220, 220);
    .container-dash {
        width: 100%;
        height: 100vh;
        background-color: var(--gray1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-top: 50px;
        scrollbar-face-color: #520a0a;
        overflow-y: auto;
        min-width: 320px;
    }

    .container-dash .groupGraph {
        width: 90%;
        display: flex;
        margin: 30px auto;
        gap: 20px;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .container-dash .groupGraph .graph {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: relative;
        background-color: #fff;
        width: 320px;
        height: auto;
        border-radius: 12px;
        border: 1px solid var(--gray1);
        box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
            0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
        padding: 10px;
        margin: 10px;
        transition: 0.6s all;

        &:hover {
            transform: scale(1.04);
        }

        .buttonGraph {
            text-decoration: none;
            border-radius: none;
            border: none;
            background-color: #fff;
            color: #000;
            font-weight: 700;

            &:hover {
                transition: 5s;
            }
        }

        span {
            &.badge-new {
                position: absolute;
                display: inline-block;
                padding: 0.3rem;
                background-color: red;
                color: #fff;
                border-radius: 10px;
                max-width: 60px;
                text-align: center;
                top: -10px;
                right: -30px;
                cursor: pointer;

                &:hover {
                    transform: scale(1);
                }
            }
        }
    }

    .container-dash .groupGraph .graph div:nth-child(1) {
        text-align: center;
        margin-left: 25px;
    }

    .container-dash .groupGraph .graph div:nth-child(1) span {
        font-size: 1.5rem;
        color: var(--gray2);
    }

    .container-dash .groupGraph .graph div:nth-child(1) h5 {
        color: var(--blue2);
    }

    .container-dash .groupGraph .graph div:nth-child(2) {
        padding-top: 5px;
        padding-left: 15px;
        border-top: 1px solid var(--gray1);
        font-size: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--blue3);
        cursor: pointer;
        gap: 10px;

        h3 {
            font-size: 0.8em;
            font-weight: 700;
            margin: 0;
        }

        a {
            margin-left: 60px;
        }
    }

    .container-dash .groupGraph .graph div:nth-child(2) a {
        color: var(--blue3);
        text-decoration: none;
    }

    .container-dash .groupGraph .graph div:nth-child(3) {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        top: -15px;
        left: 5px;
        color: var(--gray1);
        border-radius: 3px;
        box-shadow: 1px 1px 3px var(--gray2);
        font-size: 35px;
    }

    .empresas {
        width: 80%;
        padding-bottom: 50px;

        .TableTitle {
            font-weight: 700;
            font-size: 2.3em;
        }

        .btnMarket {
            background-color: #00ee00;
            border: none;
            font-weight: 700;
            font-size: 1.2em;
            border-radius: 10px;
            padding: 10px 15px;
            color: #000;
            border: 3px solid #00ee00;
            transition: 0.6s all;

            &:hover {
                background-color: #fff;
            }
        }

        .SelectCity {
            color: #009c60;
            margin: 10px 0px;

            select {
                font-weight: 700;
                border: none;

                option {
                    font-weight: 700;
                }
            }
        }
    }

    .footer {
        width: 100%;
        z-index: 4000;
        position: relative;
    }
}

/*.btnNovaEmpresa{
        display: inline-block;
        padding: 6px 12px;
        background: #137a53;
        font-size: 14px;
        color: #fff;
        text-transform: uppercase;
        border-radius: 5px;
        border: none;
        margin-top: 8px;
        transition: 0.2s;

        &:hover {
          background: #135a53;
        }


    }

    .btnSair{
      display: inline-block;
      padding: 6px 12px;
      background: #137a53;
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      border-radius: 5px;
      border: none;
      transition: 0.2s;
      height: auto;


    }*/
