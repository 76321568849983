.ViewMarket{
    background-color: #e0dcdc;
    height: 100vh;
    .contentMarket{
        padding:100px;
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        .createMarket{
            display: flex;
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #000;
            padding: 30px;
       cursor: pointer;
            .Marketform{

                display: flex;;

                    .labelMarket{

                        font-size: 20px;
                        position: absolute;

                    }
                    .inputMarket{
                      padding: 7px;
                        margin: 30px;
                        border-radius: 5px;
                        font-size: 20px;
                        border: 1px solid rgb(163, 159, 159);
                    }
                    .submitMarket{
                        width: 100px;
                        height: 50px;
                        background-color: #11c76f;
                        color: #000;
                        margin-top: 30px;
                        border-radius: 5px;
                        font-size: 20px;
                        border: 1px solid rgb(163, 159, 159);
                        &:hover {
                            transform: scale(1.06);
                          }

                    }
            }

        }

    }
.tableMarket{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

}
