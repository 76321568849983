.Perfilborder {
    #avatar {
        text-decoration: none;
    }
}

#backgroundHeader {
    background-color: #fff !important;
}

.backgroundButton {
    background-color: #00ee00 !important;
    border-radius: 8px !important;
}

.logoTipo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 80px;
    margin: auto;
}

span {
    font-family: Sora !important;
    // font-size: 1em !important;
    font-size: 1em;
    font-weight: 700 !important;
}

.makeStyles-toolbar-11 {
    button {
        background-color: #00ee00;
        margin-right: 5px;
        width: 40px !important;

        &:hover {
            background-color: #00ee00 !important;
            opacity: 0.6;
        }
    }
}
