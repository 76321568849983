.Pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;

    &.center-text {
        justify-content: center;
    }

    span {
        margin: 0 14px;
    }
}

.btn-pagination {
    border: 4px solid #00ee00;
    padding: 4px 10px;
    background-color: #00ee00;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    font-size: 1.2em;
    border-radius: 8px;
    transition: 0.5s all;
    margin: 0 4px;

    &:disabled {
        &:hover {
            background-color: #fff;
        }

        opacity: 0.4;
    }

    &:hover {
        background-color: #fff;
        color: #00ee00;
    }
}

@media screen and (min-width: 1024px) {
    .btn-pagination {
        padding: 8px 24px;
    }
}
