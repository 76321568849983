.TableContainer {
  padding-top: 40px;
  width: 100%;

}

.container {
  display: flex;
  flex-direction: column;
}

/* .btn-see-more {
      display: inline-block;
      padding: 6px 12px;
      background: #e9ae30;
      font-size: 14px;
      color: #fff;
      text-transform: uppercase;
      border-radius: 5px;
      border: none;
      margin-top: 8px;
      transition: 0.2s;

      &:hover {
        background: #e9ae30;
        color: #fff;
      }
    }
  }
*/
@media screen and (min-width: 494px) {}

@media screen and (min-width: 1024px) {}