.promoCodeWrapper {
    margin: 80px auto;
    padding: 24px;
    width: 80%;
    border: 1px solid black;
    border-radius: 15px;
    background-color: white;
    box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
        5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.codesFilter {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 8px;
    margin: 10px 0px;
}

.codesFilter button {
    width: 80%;
    padding: 5px;
    margin: 2px 0px;
    border: none;
    background-color: #009c60;
    border-radius: 10px;
    color: #cfcfcf;
}

.tablePromo {
    min-width: 1000px;
}

table.listPromoCodes {
    /* border: 3px solid #000; */
    width: 100%;
    text-align: center;
    border-radius: 0px !important;
}

table.listPromoCodes thead {
    background-color: #00ee00 !important;
    border-bottom: none !important;
    font-size: 1rem;
    color: #fff !important;
}

table.listpromoCodes td,
th {
    /* border: 1px solid #CFCFCF; */
    padding: 10px;
}

table.listpromoCodes th,
td {
    border: 1px solid #000;
    padding: 10px;
}

table.listpromoCodes td {
    font-size: 13px;
    font-weight: 700 !important;
}

button.actionToggleBTN-Ativar {
    border: 3px solid #00ee00 !important;
    background-color: #00ee00 !important;
    width: 100px;
    padding: 5px 10px !important;
    font-weight: 700;
    border-radius: 10px;
    transition: ease-in 200ms;
    color: #fff !important;
}

button.actionToggleBTN-Ativar:hover {
    transition: ease-out 200ms;
    background-color: #fff !important;
    color: #00ee00 !important;
    box-shadow: none !important;
}

button.actionToggleBTN-Desativar {
    border: 3px solid red !important;
    background-color: red;
    padding: 5px 10px !important;
    font-weight: 700;
    min-width: 100px;
    width: auto !important;
    border-radius: 10px;
    color: #fff !important;
    transition: ease-in 200ms;
}

button.actionToggleBTN-Desativar:hover {
    transition: ease-out 200ms;
    background-color: #fff;
    color: red !important;
    box-shadow: none !important;
}

button.seeMoreBTN {
    border: none;
    background-color: #56308d;
    padding: 5px;
    width: 100px;
    border-radius: 10px;
    color: #cfcfcf;
    transition: ease-in 200ms;
}

button.seeMoreBTN:hover {
    transition: ease-out 200ms;
    box-shadow: 2px 5px 16px 0px #0b325e, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

@media screen and (max-width: 768px) {
    div.listPromoCodesMobileWrapper {
        overflow: scroll;
    }
}
