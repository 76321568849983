:root {
    --color-medium: #999ea3;
}

.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    gap: 1.6rem;

    color: var(--color-medium);
    font-size: 1rem;
}

/* CREDIT: https://dev.to/afif/i-made-100-css-loaders-for-your-next-project-4eje */
.loader {
    width: 50px;
    height: 24px;
    background: radial-gradient(circle closest-side, currentColor 90%, #0000) 0%
            50%,
        radial-gradient(circle closest-side, currentColor 90%, #0000) 50% 50%,
        radial-gradient(circle closest-side, currentColor 90%, #0000) 100% 50%;
    background-size: calc(100% / 3) 12px;
    background-repeat: no-repeat;
    animation: loader 1s infinite linear;
}

@keyframes loader {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%;
    }
    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%;
    }
    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%;
    }
    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
    }
}
