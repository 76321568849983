.containerDeposit{
    width: 100%;
    padding: 60px;
    padding-left: 80px;
    height: 100%;
    background-color: #e0dcdc;

    .tableDeposit{
        background-color: #e0dcdc;
        width: 100%;
        padding-left: 150px;
    }
}