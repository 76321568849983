

$Gradiente_Fundo_body: linear-gradient(90deg, #56308d 50%, #56308d 100%);
$Gradiente_Fundo_descricao: linear-gradient(90deg, #56308d 0%, #56308d 114.75%);
$Cor_Sombra: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12),
  0px 8px 10px rgba(0, 0, 0, 0.2);
$Cor_Fundo_Box_Left: #fff;
$Cor_Borda_Campos: #c6c8c9;
$Cor_Botao_Entrar: #56308d;
$Cor_Hover_Botao_Entrar: #56308d;






#logo {
  margin: 1% auto;
  display: flex;
  width: 250px;
}
#box {
  border-radius: 20px;
  margin: 2% auto;
  display: flex;
  height: 350px;
  width: 400px;
  box-shadow: $Cor_Sombra;

  #box-left {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: $Cor_Fundo_Box_Left;

    .spanNotAccount {
      color: #707273;
    }

    form {
      height: 250px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      margin-top: 50px;

      span {
        align-self: flex-start !important;
        font-size: 0.75rem;
      }

      .erros {
        font-size: 0.7rem;
        color: red;
      }

      h5 {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 20px;
        font-family: "Montserrat-Light";
      }

      .form-group {
        label {
          font-family: "Montserrat-Light";
        }
      }

      .button_Entrar {
        width: 100%;
        padding: 8px 12px;
        background: $Cor_Botao_Entrar;
        color: $Cor_Fundo_Box_Left;
        box-shadow: $Cor_Sombra;
        border: none;
        border-radius: 7px;
        cursor: pointer;
        font-size: 0.875rem;
        font-family: "Montserrat-Light";

        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: $Gradiente_Fundo_body;
          box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
        }

        &:disabled {
          opacity: 0.7;
          cursor: wait;
        }
      }
    }
  }


  .LoginForm {
    width: 100%;
    padding: 0 16px;


    .showPass{
     width: 100px;
    }


    .form-group {
      width: 100%;
      margin-bottom: 16px;

      label {
        font-size: 14px;
        margin-bottom: 2px;
      }

      input {
        width: 100%;
        outline: none;



        &:focus {
          background: #fff;

        }

      }
    }

    .error-message {
      color: #f44336;
      margin-left: 0;
    }
  }





}

@media screen and (width: 768px) {
  #logo {
    margin-top: 30px !important;
    width: 300px;
  }
  #box {
    padding: 0;
    width: 310px;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;

    #box-left {
      align-self: center;
      justify-content: center;
      align-items: center;
      max-width: 310px;
      height: 420px;
      border-radius: 7px;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    }

  }
}

@media screen and (max-width: 740px) {
  #logo {
    margin-top: 20px !important;

    width: 250px;
  }

  #box {
    padding: 0;
    width: 310px;
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;

    #box-left {
      align-self: center;
      justify-content: center;
      align-items: center;
      max-width: 310px;
      height: 420px;
      border-radius: 7px;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    }


  }
}
@media screen and (width: 320px) {
  #logo {
    margin-top: 0 !important;
    width: 100ox;
  }

  #box {
    width: 280px;
    height: 420px;
    #box-left {
      width: 280px;
      height: 420px;
    }
  }
}

.customModal {
  max-width: 300px;
  width: 100%;
  color: rgba(255, 0, 0, 0.726);
}
